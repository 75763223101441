import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IonSlides, ModalController} from '@ionic/angular';
import {Photo, Post} from "../../models/photo";
import { v4 as uuidv4 } from 'uuid';
import {PhotoAlbum} from "../../services/photo-album.service";
import {Storage} from "@ionic/storage-angular";

@Component({
  selector: 'app-load',
  templateUrl: './load.page.html',
  styleUrls: ['./load.page.scss'],
})
export class LoadPage {

  progress;
  user;
  files;
  post;

  @Input() event;
  constructor(
    private photoAlbum: PhotoAlbum,
    public modalCtrl: ModalController,
    private storage: Storage
  ) {
    this.storage.get('user').then( res => this.user = res);
  }


  ngOnInit() {
    this.loadAllFiles(this.event);
  }

  async close() {
    this.modalCtrl.dismiss();
  }

  private loadAllFiles(event){
    // console.log("loadAllFiles", event);
    const reader = new FileReader();
    // reader.addEventListener('progress',  (x) => {
    //   this.progress = (event.loaded / event.total) * 100;
    //   console.log("progress 1", this.progress, x)
    // }, false);
    this.files = [...event.target.files];
    this.post = {
      created_at: Date.now(),
      author: this.user,
      photos: [],
    }
    // console.log('files', this.files)

    // this.files.map((file) => {
    //   let detail : any = {};
    //   detail.progress = 0;
    //   detail.file = file;
    //   const reader2= new FileReader();
    //   reader2.readAsDataURL(file);
    //   reader2.onload = (_event) => {
    //     detail.dataUrl = reader2.result;
    //   }
    //   return detail
    // });

    this.files.forEach((file, idx) => {
      let detail : any = {};
      detail.idx = idx;
      detail.progress = 0;
      detail.file = file
        this.loadFile(detail, true);
      })
  }

  private loadFile(detail, callback?){

    // console.log(detail, callback)
    let file = detail.file;
    const reader = new FileReader();
    const path = "afla/debucher2022/";
    const public_id = path + uuidv4();

    reader.addEventListener('load',  () => {
      file = reader.result as string;
      if (callback) {
        // this.photoAlbum.uploadFile(event.target.files[0], public_id, "debucher2022").subscribe(
        this.photoAlbum.uploadFile(file, public_id, "debucher2022").subscribe(
          res => {
            // console.log("changeListener uploadFile", res)
            if(res.loaded && res.total) {
              detail.progress = res.loaded / res.total
              this.files[detail.idx] = detail
            }
            if (res.body) {
              detail.status = "ok";
              detail.secure_url = res.body.secure_url;
              this.files[detail.idx] = detail;

              const newPhoto: Photo = {
                ilike: false,
                secure_url: res.body.secure_url,
                publicId: res.body.public_id,
                created_at: res.body.created_at,
                format: res.body.format,
                height: res.body.height,
                metadata: res.body.tags,
                width: res.body.width,
                author: this.user,
                likes: []
              }

              this.photoAlbum.addPhoto(newPhoto).then(res => this.post.id = res.id)

              // this.post.photos.push(newPhoto);
              // if(this.post.photos.length == this.files.length){
              //   this.photoAlbum.addPost(this.post).then(res =>
              //     console.log(res)
              //   )
              // }
              // this.photoAlbum.addPost(this.post).then(res => this.post.id = res.id)


            }

          },
          err => console.log("changeListener err", err)
        )
      }
    }, false);

    if (file) {
      reader.readAsDataURL(file);
      // console.log(file)
    }
  }

  sendMessage(message){
    this.photoAlbum.updateMessage(this.post.id, message).then( res =>
      this.close()
    )
  }

}
