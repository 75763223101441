import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {MatriquizzPage} from "./modals/matriquizz/matriquizz.page";

const routes: Routes = [
  {
    path: 'screen',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./screen/tab3.module').then(m => m.Tab3PageModule)
      }
    ]
  },
  {
    path: 'quizz',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modals/matriquizz/matriquizz.module').then(m => m.MatriquizzPageModule)
      }
    ]
  },
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
      anchorScrolling : 'enabled',
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
