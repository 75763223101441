import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {CloudinaryModule} from "@cloudinary/ng";
import {HttpClientModule} from "@angular/common/http";
import {AngularFireModule} from "@angular/fire/compat";
import {AngularFireDatabaseModule} from "@angular/fire/compat/database";
import {environment} from "../environments/environment.prod";
import {AngularFireFunctionsModule} from "@angular/fire/compat/functions";
import {IonicStorageModule} from "@ionic/storage-angular";
import {ConnectPage} from "./modals/connect/connect.page";
import { LoadPage } from './modals/load/load.page';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';


@NgModule({
  declarations: [AppComponent, ConnectPage, LoadPage],
  entryComponents: [],
  imports: [
    BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule,
    IonicStorageModule.forRoot(),
    CloudinaryModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireFunctionsModule,
    AngularFireDatabaseModule],
  providers: [
    {
      provide: RouteReuseStrategy, useClass: IonicRouteStrategy,
    },
    Camera,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
