import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Member} from "../models/photo";

@Injectable({
  providedIn: 'root'
})

export class MemberService {

  public member: BehaviorSubject<Member> = new BehaviorSubject(null);

  constructor() {}

  }
