// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCU1g51ztsX-dVjkMhyZfnripyRWQ5Nk7s",
    authDomain: "afla-photos.firebaseapp.com",
    projectId: "afla-photos",
    storageBucket: "afla-photos.appspot.com",
    messagingSenderId: "921801102242",
    appId: "1:921801102242:web:4575baa1399899429998fb"
  },
  cloudinaryConfig: {
    cloudname : "noeupapp"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
