import {Component, Directive, ElementRef, Input, NgModule, OnInit, ViewChild} from '@angular/core';
import {IonSlides, ModalController} from '@ionic/angular';
import firebase from "firebase/compat";
import User = firebase.User;
import {Storage} from "@ionic/storage-angular";
import {from, Observable, of} from "rxjs";
import {map, take, tap} from "rxjs/operators";
import {Member, Photo} from "../../models/photo";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {getDocs, where} from "@angular/fire/firestore";
import { MemberService } from 'src/app/services/member.service';

@Component({
  selector: 'app-connect',
  templateUrl: './connect.page.html',
  styleUrls: ['./connect.page.scss'],
})

// @Directive({
//   selector: '[autofocus]'
// })

export class ConnectPage {

  suggestions: Observable<Member[]> = of([]);
  @ViewChild('matricule') matriculeInput; //:  HTMLInputElement;

  constructor(
      public modalCtrl: ModalController,
      private storage: Storage,
      private ngFirestore: AngularFirestore,
      public memberService: MemberService
      //private host: ElementRef,
  ) { }

  // @ViewChild('input', { static: false })
  // set input(element: ElementRef<HTMLInputElement>) {
  //   if(element) {
  //     element.nativeElement.focus()
  //   }
  // }

  // ionViewDidEnter(){
  //   setTimeout(() => this.host.nativeElement.focus(), 500);
  // }
  ionViewDidEnter(){
    //this.matriculeInput.nativeElement.focus();
    setTimeout(() => {
      this.matriculeInput.setFocus()
    }, 1000);
  }

  getMembers(search: string): Observable<Member[]> {
    // return this.ngFirestore.collection('members', ref => ref.orderBy('created_at','desc'))
    return this.ngFirestore.collection('members', ref => ref
      .where('matricule','>=', search )
      .where('matricule','<', search+ '\uf8ff' )
      )
      .snapshotChanges().pipe(
        // tap(res=> console.log(res)),
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Member;
          const id = a.payload.doc.id;
          return { id, ...data };
        }))
      )
  }

  // getMembrers(search): Observable<any> {
  //   const membersRef = this.ngFirestore.collection('members', ref => ref.orderBy('created_at','desc'))
  //   const query =  query(membersRef, where("matricule", ">=", search);
  //
  //   return await getDocs(query);
  //     .pipe(
  //       map(actions => actions.map(a => {
  //         const data = a.payload.doc.data() as Photo;
  //         const id = a.payload.doc.id;
  //         return { id, ...data };
  //       }))
  //     )
  // }


   async close() {
      this.modalCtrl.dismiss();
    }

    findUserInList(matricule){
    // console.log(matricule.detail.value)
     if (matricule.detail.value.length > 2) {
       this.suggestions = this.getMembers(matricule.detail.value);
     } else {
       this.suggestions = of([]);
     }
    }

    storeUser(user) {
      this.memberService.member.next(user);
      this.storage.set("user", user);
      this.close();
    }

}
