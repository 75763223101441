export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyCU1g51ztsX-dVjkMhyZfnripyRWQ5Nk7s",
    authDomain: "afla-photos.firebaseapp.com",
    projectId: "afla-photos",
    storageBucket: "afla-photos.appspot.com",
    messagingSenderId: "921801102242",
    appId: "1:921801102242:web:4575baa1399899429998fb"
  }
};

