import { Component } from '@angular/core';
import {ModalController, Platform} from "@ionic/angular";
import {SplashScreen} from "@awesome-cordova-plugins/splash-screen/ngx";
import { ConnectPage } from './modals/connect/connect.page';
import { Storage } from '@ionic/storage-angular';
import {BehaviorSubject} from "rxjs";
import { Member } from './models/photo';
import {MemberService} from "./services/member.service";


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  constructor(
    private platform: Platform,
    private storage: Storage,
    public modalController: ModalController,
    private memberService: MemberService,
    //private splashScreen: SplashScreen,
    //private statusBar: StatusBar
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    this.storage.create();
    const connected = await this.storage.get('user');
    //this.splashScreen.show();
    this.memberService.member.next(connected);
    if(!connected){
      this.openConnectModal();
    }


    this.platform.ready().then(() => {
      //this.statusBar.styleDefault();
      // let status bar overlay webview
      // if (this.platform.isWebView()) {
      //   this.statusBar.overlaysWebView(true);
      //   // set status bar to white
      //   this.statusBar.backgroundColorByHexString('#ffffff');
      // }
      //this.splashScreen.hide();
    });
  }

  async openConnectModal() {
    const modal = await this.modalController.create({
      component: ConnectPage
    });
    await modal.present();
    const {data} = await modal.onWillDismiss();
  }
}
