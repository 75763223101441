import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import {Subject} from 'rxjs/internal/Subject';
import {Cloudinary, CloudinaryImage} from '@cloudinary/url-gen';
import {BehaviorSubject, from, Observable} from "rxjs";
import {Member, Photo, Post} from "../models/photo";
import {map, mergeMap, switchMap, take, tap} from "rxjs/operators";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {Config} from "@ionic/angular";
import {AngularFireFunctions} from "@angular/fire/compat/functions";
// import firebase from "firebase/compat";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import FieldValue = firebase.firestore.FieldValue;
import Timestamp = firebase.firestore.Timestamp;
import { MemberService } from './member.service';


@Injectable({
  providedIn: 'root'
})

export class PhotoAlbum {

  public photos: BehaviorSubject<Photo[]> = new BehaviorSubject([]);
  _photos = [];

  lastphotoGet;

  // tableData will contains the document items get from collection
  tableData: any[] = [];
  // save first document in snapshot of items received
  firstInResponse: any = [];
  // save last document in snapshot of items received
  lastInResponse: any = [];
  // keep the array of first document of previous pages
  prev_strt_at: any = [];
  // maintain the count of clicks on Next Prev button
  pagination_clicked_count = 0;
  // two buttons will be needed by which next data or prev data will be loaded
  // disable next and prev buttons
  disable_next: boolean = false;
  disable_prev: boolean = true;


  constructor(
      private http: HttpClient,
      private ngFirestore: AngularFirestore,
      private functions: AngularFireFunctions,
      public memberService: MemberService,
  ) {
    // initially load first 5 records/items from database
    //this.loadItems();
    if(!this.lastphotoGet) this.lastphotoGet = Timestamp.now();
  }

  // add a document
  push_prev_startAt(prev_first_doc) {
    this.prev_strt_at.push(prev_first_doc);
  }

  // remove non required document
  pop_prev_startAt(prev_first_doc) {
    this.prev_strt_at.forEach(element => {
      if (prev_first_doc.data().id == element.data().id) {
        element = null;
      }
    });
  }

  // return the Doc rem where previous page will startAt
  get_prev_startAt() {
    if (this.prev_strt_at.length > (this.pagination_clicked_count + 1)) {
      this.prev_strt_at.splice(this.prev_strt_at.length - 2, this.prev_strt_at.length - 1);
    }
    return this.prev_strt_at[this.pagination_clicked_count - 1];
  }
//
//   getItems() {
//     this.ngFirestore.collection('photoAlbum', ref => ref
//       .limit(5)
//       .orderBy('timestamp', 'desc')
//     ).snapshotChanges()
//       .subscribe(photos => {
//         if (!photos.length) {
//           console.log("No Data Available");
//           return false;
//         }
//         this.firstInResponse = photos[0];
//         this.lastInResponse = photos[photos.length - 1];
//
//         this.tableData = [];
//         for (let item of photos) {
//           this.tableData.push(item.data());
//         }
//
//         // initialize values
//         this.prev_strt_at = [];
//         this.pagination_clicked_count = 0;
//         this.disable_next = false;
//         this.disable_prev = false;
//
//         // push first item to use for Previous action
//         this.push_prev_startAt(this.firstInResponse);
//       }, error => {
//         console.log(error);
//       });
// //   }, error => {
// //   console.log(error);
// // });
// }


  formatData(photo){
    // console.log("phto", photo.secure_url)
      if (photo.secure_url?.includes('video')) {
        photo.mode = "video";
        photo.url = 'https://res.cloudinary.com/noeupapp/video/upload/' + photo.publicId + ".mp4"
      } else {
        photo.mode = "image";
        photo.url = 'https://res.cloudinary.com/noeupapp/image/upload/c_scale,w_800/' + photo.publicId;
        photo.urllarge = 'https://res.cloudinary.com/noeupapp/image/upload/' + photo.publicId;
      }
      photo.ilike = !!photo.likes?.find(x => x == (this.memberService.member.value.firstname + ' ' + this.memberService.member.value.lastname));
      return photo;
  }

  // getPhotos(): Observable<Photo[]> {
  getPhotos(perpage: number) {
    this.ngFirestore.collection('photoAlbum', ref => ref.limit(perpage).orderBy('fs_timestamp','desc')
      .startAfter(this.lastphotoGet)
      //.startAfter(this.firstInResponse)
      //.startAt(this.get_prev_startAt())
      //.endBefore(this.firstInResponse)

      )
      .snapshotChanges().pipe(
      map( photos => {
        this.firstInResponse = photos[0].payload.doc;
        this.lastInResponse = photos[photos.length - 1].payload.doc;
        return photos
        }),
      map(photos => photos.map(a => {
          let data = a.payload.doc.data() as Photo;
          const id = a.payload.doc.id;
          data = this.formatData(data)
          return { id, ...data };
        })),
      ).subscribe(
        res => {
          // console.log("new photos", res)
          //res.forEach(photo => this._photos.unshift(photo) )
          this._photos = res.concat(this._photos)
          this.photos.next(this._photos);
        }
      )
  }

  // return this.ngFirestore.collection('photoAlbum', ref => ref.limit(5).orderBy('created_at','desc'))
  //   .snapshotChanges().pipe(
  //     map( photos => {
  //       this.firstInResponse = photos[0];
  //       this.lastInResponse = photos[photos.length - 1];
  //       return photos
  //     }),
  //     map(photos => photos.map(a => {
  //       const data = a.payload.doc.data() as Photo;
  //       const id = a.payload.doc.id;
  //       return { id, ...data };
  //     })),
  //     tap(() => console.log('first last',this.firstInResponse, this.lastInResponse))
  //   )

  nextPage(event, perpage) {
    this.disable_next = true;
    this.ngFirestore.collection('photoAlbum',
        ref => ref.limit(perpage)
                          .orderBy('created_at','desc')
                          .startAfter(this.lastInResponse))
      .snapshotChanges().pipe(
      tap((res) => console.log('res',res)),
      map( photos => {
        if (photos.length) {
          this.firstInResponse = photos[0].payload.doc;
          this.lastInResponse = photos[photos.length - 1].payload.doc;
        }
        return photos
      }),
      map(photos => photos.map(a => {
        let data = a.payload.doc.data() as Photo;
        const id = a.payload.doc.id;
        data = this.formatData(data)
        return { id, ...data };
      })),
    ).subscribe(
      res => {
        if (!res.length) {
          console.log("No More Data Available");
          this.disable_next = true;
          // event.target.disabled = true;
        }
        this.formatData(res);
        // this._photos.push(this.formatData(res))
        this.photos.next(this._photos);
        //this.push_prev_startAt(this.firstInResponse);
        //res.forEach(photo => this._photos.push(photo))
        this._photos = this._photos.concat(res)
        event.target.complete();
      }
    )
  }

  addPhoto(photo: Photo) {
    // photo.fs_timestamp = /*firebase.firestore*/FieldValue.serverTimestamp()
    photo.fs_timestamp = Timestamp.now();
    return this.ngFirestore.collection('photoAlbum').add(photo);
  }

  updateMessage(id, value: string) {
    let item = this.ngFirestore.doc<Post>('photoAlbum/'+id)
    let newVal: Partial<Photo> = { message: value };
    return item.update(newVal);
  }

  likePhoto(id, value: string, status: boolean) {
    // console.log("likePhoto",status);
    let item = this.ngFirestore.doc<Photo>('photoAlbum/'+id)
    let newVal: Partial<Photo>;
    if (status) {
      newVal = { likes: FieldValue.arrayUnion(value)}
    } else {
      newVal = { likes: FieldValue.arrayRemove(value) }
    }
    return item.update(newVal);
  }

  addPost(post: Post) {
    return this.ngFirestore.collection('photoAlbum').add(post);
  }

  updatePost(path, key, value) {
    let item = this.ngFirestore.doc<Post>(path)
    let newVal = JSON.parse("{" + key + ": '" + value + "'}");
    item.update(newVal);
  }

  private askSignature(public_id:string,unixTS,tag,eager){
    let bodyForSignature = {
      // eager: eager,
      public_id: public_id,
      tags: tag,
      timestamp: unixTS,
    };

    const callable = this.functions.httpsCallable('getSignature');
    return callable(bodyForSignature);
  }

  private uploadCloudinary(bodySigned,url): Observable<HttpEvent<any>>{
    let headers =  new HttpHeaders({'X-Requested-With':'XMLHttpRequest'});
    let  req = new HttpRequest('POST', url, bodySigned, {headers : headers, reportProgress: true});
    // console.log('uploadCloudinary', req);
    return this.http.request(req);
  }

  uploadFile(file, public_id:string, tag:string, progress?, eager?): Observable<any> {
    const url = `https://api.cloudinary.com/v1_1/noeupapp/auto/upload`;
    let unixTS = Math.floor(Date.now() / 1000).toString();
    let api_key = '797247229862765'; // to configuration
    let bodySigned = undefined;
    // eager = 'w_400,h_300,c_pad|w_260,h_200,c_crop';

    return this.askSignature(public_id, unixTS , tag, eager).pipe(
      // tap(res => console.log('signature ', res)),
      switchMap(
        res => {
                  bodySigned = {
                    api_key: api_key,
                    // eager: eager,
                    file: file,
                    public_id: public_id,
                    timestamp: unixTS,
                    tags: tag,
                    signature: res
                  };
                  let temp = this.uploadCloudinary(bodySigned, url);
                  // console.log(temp);
                  return temp;
                })
        // err => console.log('error here', err))
    )};
  }
